export function getSignalingUrl(peerId, roomId)
{
	const port =
		process.env.NODE_ENV !== 'production' ?
			window.config.developmentPort
			:
			window.config.productionPort;

	const url = `wss://conference-vm-old.meshstream.io:443/?peerId=${peerId}&roomId=${roomId}&jwtToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImtlbmNoZW4iLCJpYXQiOjE2MjEyMjk0NjZ9.7PgyGxPnVaxYNUETR2djnc7oHpOTTK9_F-4IfFE9xAo`;

	return url;
}
